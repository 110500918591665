import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import livecasts from '../images/meta/livecasts.jpg';

const DEFAULTS = {
  title: 'Livecasts - Webcasting service you can rely on',
  titleTemplate: '%s · Livecasts',
  description:
    'Managed live streaming solution covering your virtual and on-site events, providing great online experience for your audience.',
  image: livecasts,
  siteUrl: 'https://www.livecasts.eu',
};

const SEO = (props) => {
  let { pathname } = useLocation();

  let seo = {
    ...DEFAULTS,
    url: `${DEFAULTS.siteUrl}${pathname}`,
    ...props,
  };

  let image = `${seo.siteUrl}${seo.image}`;

  return (
    <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
      <meta name="description" content={seo.description} />

      <link rel="canonical" href={seo.url} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:site_name" content="Livecasts" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />

      <link rel="image_src" href={image} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />
    </Helmet>
  );
};

export default SEO;
