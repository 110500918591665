import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import Button from '../components/QuoteButton';
import logo from '../images/header/livecasts-logo.svg';
import mobileLogo from '../images/header/livecasts-mobile-logo.svg';

export default function Header(props) {
  return (
    <header {...props}>
      <div className="container flex items-center justify-between py-6">
        <Link to="/">
          <img src={logo} alt="Livecasts" />
        </Link>
        <div className="hidden md:flex items-center space-x-12">
          <div className="flex space-x-8 items-center">
            <Link to="/virtual-events" className="link focus-ring">
              Virtual Events
            </Link>
            <Link to="/on-site-events" className="link focus-ring">
              On-site Events
            </Link>
          </div>
          <Link className="btn focus-ring text-base py-3 px-10" to="/get-a-quote">
            Get a Quote
          </Link>
        </div>

        <div className="block md:hidden">
          <Menu right width="100%">
            <div>
              <img src={logo} alt="Livecasts" className="xs:block hidden" />
              <img src={mobileLogo} alt="Livecasts" className="block xs:hidden" />
            </div>
            <div className="flex flex-col">
              <div className="pb-24 pt-8 flex flex-col items-center space-y-8">
                <Link
                  to="/"
                  className="block text-blue text-opacity-50 text-3xl font-bold hover:text-opacity-100"
                >
                  Home
                </Link>
                <Link
                  to="/virtual-events"
                  className="block text-blue text-3xl font-bold focus-ring transition hover:text-bluesh-grey"
                >
                  Virtual Events
                </Link>
                <Link
                  to="/on-site-events"
                  className="block text-blue text-3xl font-bold focus-ring transition hover:text-bluesh-grey"
                >
                  On-site Events
                </Link>
                <div>
                  <Button style="block" />
                </div>
              </div>
              <div className="pt-4 border-t border-border-grey text-lg space-y-4 flex flex-col items-center">
                <p className="text-bluesh-grey text-lg">Contact us</p>
                <p className="text-blue">info@livecasts.eu</p>
                <p className="text-blue">+32 2 881 01 15</p>
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </header>
  );
}
