import React from 'react';
import { Link } from 'gatsby';

export default function Button(props) {
  return (
    <Link
      className={`btn focus-ring text-xl py-3 sm:py-4 px-6 sm:px-11 ${props.style}`}
      to="/get-a-quote"
    >
      Get a Quote
    </Link>
  );
}
